import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import apiService from "../api/apiService";

const Supplier = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSuppliers = async () => {
    try {
      const token = await getAccessTokenSilently();
      //        const data = await apiService.getSuppliers(token);

      const data = await apiService.getSuppliers(token);
      setSuppliers(data.allSuppliers);
      console.log("Data:", data.allSuppliers);
      console.log("Sup. Data:", suppliers);
      console.log("token:" + token);
    } catch (error) {
      console.error("Error fetching suppliers:", error);
      // Handle error as needed (e.g., show error message)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <h2>Suppliers</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Description</th>
              <th>Contact Person</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Address</th>
              <th>City</th>
              <th>Country</th>
              <th>Postal Code</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {suppliers.length > 0 ? (
              suppliers.map((supplier, index) => (
                <tr key={supplier.id}>
                  <td>{index + 1}</td>
                  <td>{supplier.supplierName}</td>
                  <td>{supplier.supplierDescription}</td>
                  <td>{supplier.contactPerson}</td>
                  <td>{supplier.email}</td>
                  <td>{supplier.phoneNumber}</td>
                  <td>{supplier.address}</td>
                  <td>{supplier.city}</td>
                  <td>{supplier.country}</td>
                  <td>{supplier.postalCode}</td>
                  <td>
                    {supplier.lastUpdated != null
                      ? supplier.lastUpdated
                      : "..."}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default Supplier;
