import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import apiService from "../api/apiService";

const Inventory = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInventory = async () => {
    try {
      const token = await getAccessTokenSilently();
      const data = await apiService.getInventory(token);
      setInventoryData(data.allInventory);
      console.log("Data:", data.allInventory);
      console.log("Inv. Data:", inventoryData);
      console.log("token:" + token);
    } catch (error) {
      console.error("Error fetching inventory:", error);
      // Handle error as needed (e.g., show error message)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <h2>Inventory</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Description</th>
              <th>Category</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Supplier ID</th>
              <th>Location</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {inventoryData.length > 0 ? (
              inventoryData.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.productName}</td>
                  <td>{item.description}</td>
                  <td>{item.category}</td>
                  <td>{item.quantity}</td>
                  <td>{item.unitPrice}</td>
                  <td>{item.supplierId}</td>
                  <td>{item.location}</td>
                  <td>{item.lastUpdated != null ? item.lastUpdated : "..."}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default Inventory;
