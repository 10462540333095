// Settings.js
import React from "react";
import { Container, Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import apiService from "../api/apiService";

const Settings = () => {
  const { getAccessTokenSilently } = useAuth0();
  const handleDeleteAllData = async () => {
    try {
      const token = await getAccessTokenSilently();
      var response = await apiService.deleteAllCompanyData(token);
      console.log(response);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <Container className="my-5">
      <h2>Company Settings</h2>
      <p>
        Here you can manage settings for your company. The button below will
        delete all the data for your company.
      </p>
      <div class="alert alert-danger" role="alert">
        Please, keep in mind that employees accounts will be deleted within 2
        weeks!
      </div>
      <Button variant="danger" onClick={handleDeleteAllData}>
        Delete
      </Button>
    </Container>
  );
};

export default Settings;
