import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:80/v1",
  headers: {
    "Content-Type": "application/json",
    //companyId: "1",
  },
});

const apiService = {
  getInventory: async (token) => {
    try {
      console.log("token in apiService:" + token);
      const response = await apiClient.get("/inventory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getSuppliers: async (token) => {
    try {
      const response = await apiClient.get("/suppliers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteAllCompanyData: async (token) => {
    try {
      const response = await apiClient.delete("/suppliers/delete", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default apiService;
