import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from "./components/Navbar";
import Footer from "./components/Footer";
import Inventory from "./components/Inventory";
import Supplier from "./components/Supplier";
import About from "./components/About";
import Contact from "./components/Contact";
import Homepage from "./components/Homepage";
import LoginButton from "./components/LoginButton";
import LogoutButton from "./components/LogoutButton";
import Profile from "./components/Profile";
import Settings from "./components/Settings";
import { useAuth0 } from "@auth0/auth0-react";

const App = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <NavigationBar />
        <Routes>
          <Route
            path="/inventory"
            element={isAuthenticated ? <Inventory /> : null}
          />
          <Route
            path="/supplier"
            element={isAuthenticated ? <Supplier /> : null}
          />
          <Route path="/about" element={isAuthenticated ? <About /> : null} />
          <Route
            path="/contact"
            element={isAuthenticated ? <Contact /> : null}
          />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/" element={<LoginButton />} />
          <Route
            path="/profile"
            element={isAuthenticated ? <Profile /> : null}
          />
          <Route path="/settings" element={<Settings />} />
          <Route
            path="/logout"
            element={isAuthenticated ? <LogoutButton /> : null}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
