import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="bg-primary text-white text-center py-1 mt-auto">
      <Container>
        <p>&copy; 2024 SCMS. All rights reserved.</p>
      </Container>
    </footer>
  );
};

export default Footer;
