import React from "react";
import { Container } from "react-bootstrap";

const About = () => {
  return (
    <Container className="my-5">
      <h2>About the software</h2>
      <p>
        The supply chain is an indispensable component of the modern industrial
        ecosystem. It serves to expedite product manufacturing, ensuring optimal
        cost efficiency and superior quality to gain a competitive edge in the
        market. Effective supply chain management necessitates robust software
        solutions that empower professionals to execute their roles swiftly and
        seamlessly. Typically, such software should encompass functionalities
        for directing procurement, managing operations, orchestrating logistics,
        and establishing marketing channels. Additionally, advanced platforms
        may offer comprehensive statistical analysis and even incorporate
        AI-driven models for demand forecasting. These features collectively
        enhance operational agility, minimize lead times, and optimize resource
        allocation throughout the supply chain.
      </p>
    </Container>
  );
};

export default About;
