import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import apiService from "../api/apiService";

const Homepage = () => {
  const [selectedOption, setSelectedOption] = useState("local");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    // Update API base URL based on selection
    if (event.target.value === "local") {
      apiService.setBaseUrl("http://localhost:80/v1");
    } else if (event.target.value === "deployed") {
      apiService.setBaseUrl("http://4.182.192.137/v1/");
    }
  };

  return (
    <Container className="my-5">
      <h2>Welcome to SCMS</h2>
      <Form>
        <Form.Group>
          <Form.Label>Select application type:</Form.Label>
          <div>
            <Form.Check
              type="radio"
              label="Local Kubernetes Application"
              name="applicationType"
              id="localRadio"
              value="local"
              checked={selectedOption === "local"}
              onChange={handleOptionChange}
            />
            <Form.Check
              type="radio"
              label="Deployed Application"
              name="applicationType"
              id="deployedRadio"
              value="deployed"
              checked={selectedOption === "deployed"}
              onChange={handleOptionChange}
              className="mt-2"
            />
          </div>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default Homepage;
